
import {
    ExclamationCircleOutlined
} from '@ant-design/icons-vue';
import AddressBook from '@/views/user/basic/address_book/compontent/simple.vue';
import address from '@/utils/address';
import cachePostCode from "@/views/admin/order/hook/cachePostCode"
import itemHook from "@/views/admin/order/hook/itemHook"
import AnalyzePostalCode from "@/components/Address/hook/analyze-postalcode.js"
import {
    ref,
    watch
} from "vue"
import AddressGroup from "@/views/admin/order/components/address-group.vue"

export default {
    components: {
        ExclamationCircleOutlined,
        AddressBook,
        AddressGroup
    },
    data() {
        return {
            radioStyle: {
                display: 'flex',
                whiteSpace: 'normal',
                alignItems: 'center',
                margin: "10px 0px"
            },
            // Freight Class
            freight_class: [],
            // 显示地址簿
            showBook: false,
            // 测试费用加载状态
            rates_loading: false,
            //
            rates_timer: 0,
            // 测算费用结果
            rates_result: {},
            // 当前选择的渠道
            rates_current_ship_method_id: 0,
            // 加载状态
            loading: false,
            // 查询地址加载状态
            query_address_loading: false,
            // 自定义显示内容
            custom_content: '',
            // 邮编加载状态
            postal_code_loading: false,
            // 焦点控制器
            onFoucsControl: false,
            // 判断地址是否改变
            boolAddress: false
        };
    },
    watch: {
        'form.global_reference1': {
            handler(newVal, oldVal) {
                for (var i in this.packages) {
                    if (!this.packages[i].reference1 || this.packages[i].reference1 === oldVal) {
                        this.packages[i].reference1 = newVal;
                    }
                }
            }
        },
        'form.global_reference2': {
            handler(newVal, oldVal) {
                for (var i in this.packages) {
                    if (!this.packages[i].reference2 || this.packages[i].reference2 === oldVal) {
                        this.packages[i].reference2 = newVal;
                    }
                }
            }
        },
        'form.address': {
            handler() {
                if (this.address.shipper_address_id) localStorage.setItem('cache_shipper_address_id', JSON.stringify(this.address.shipper_address_id));

                this.onFoucsControl = true;
                this.boolAddress = true;
                this.test_rates();
            },
            deep: true
        },
        'form.address.name': {
            handler() {
                if (/^[A-Za-z0-9]{4}$/.test(this.address.name)) {
                    //自动填充FBA地址
                    this.queryFbaAddress(this.address.name);
                }
            }
        },
        'form.packages': {
            handler() {
                this.onFoucsControl = true;
            },
            immediate: true,
            deep: true
        },
        rates_current_ship_method_id: {
            handler(newVal, oldVal) {
                if (oldVal && newVal !== this.rates_result.ship_method_id) {
                    this.onFoucsControl = true;
                }
            }
        }
    },
    computed: {
        address() {
            return this.form.address;
        },
        packages() {
            return this.form.packages;
        },
        extra_packages() {
            let result = {};

            this.packages.forEach((item, index) => {
                for (var k in item) {
                    result[`${index}.${k}`] = `${item[k]}`;
                }
            });

            return result;
        },
        charges() {
            return this.rates_result.charges || [];
        }
    },
    mounted() {
        this.query();
    },
    methods: {
        query() {
            Promise.all([this.$http.get('/user/shipper/simple_list'), this.$http.get('/user/order/freight_class'), this.$http.get(`/system_config/content/order_page_content`)]).then(res => {
                if (res[0].data.code === 0) {
                    this.shippers = res[0].data.data;

                    if (this.shippers.length) {
                        this.form.address.shipper_address_id = this.shippers[0].id;
                    }
                } else {
                    this.$message.error(res[0].data.msg);
                }

                if (res[1].data.code === 0) {
                    this.freight_class = res[1].data.data;
                } else {
                    this.$message.error(res[1].data.msg);
                }

                if (res[2].data.code === 0) {
                    this.custom_content = res[2].data.data;
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        /**
         * 移除箱
         */
        removePackage(row) {
            if (this.packages.length > 1) {
                this.packages.splice(this.packages.indexOf(row), 1);

                this.$message.success('user_order.tips.delete_success');
            }
        },
        /**
         * 监视地址框粘贴地址自动识别转换
         */
        handlePaste(e) {
            var content = e.clipboardData.getData('text');
            var row = address.parse(content);

            this.$nextTick(() => {
                row && this.chooseAddress(row);
                !row && (this.address.name = content);
            });

            e.preventDefault();
        },
        /**
         * 选择地址
         */
        chooseAddress(row) {
            this.address.name = row.name;
            this.address.company_name = row.company_name;
            this.address.address1 = row.address1;
            this.address.address2 = row.address2;
            this.address.address3 = row.address3;
            this.address.city = row.city;
            this.address.state_code = row.state_code;
            this.address.postal_code = row.postal_code;
            this.address.country_code = row.country_code;
            this.address.phone_number = row.phone_number;

            this.$refs.address_form.clearValidate();
        },
        /**
         * 查询FBA地址
         */
        queryFbaAddress(name) {
            this.query_address_loading = true;

            this.$http.get(`/user/address_book/query_single_address/${name}`).then(res => {
                this.query_address_loading = false;

                if (res.data.code === 0) {
                    this.chooseAddress(res.data.data);
                }
            }).catch(() => this.query_address_loading = false);
        },
        /**
         * 测试费用
         */
        test_rates() {
            // 焦点控制开关
            if (!this.onFoucsControl) {
                return
            }

            if (!this.$refs.address_form) {
                return;
            }

            // 判断是否偏远地区
            if (this.boolAddress) {
                this.$refs.address_form.validate().then(() => {
                    var postData = Object.assign({
                        shipper_address_id: this.address.shipper_address_id
                    }, this.form);
                    this.rates_loading = true;
                    this.$http.post('/user/order/address_type', postData).then(res => {
                        if (res.data.code == 0) {
                            this.rates_result = res.data.data;
                        }
                    }).finally(() => {
                        this.rates_loading = false;
                        this.onFoucsControl = false;
                        this.boolAddress = false;
                    })
                })
            }
        },
        /**
         * Rates排序
         */
        sort_rates(a, b) {
            return (a.active ? a.amount : 999999) - (b.active ? b.amount : 999999);
        },
        base64ToBlob(text, content_type) {
            var byteString = atob(text);
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var intArray = new Uint8Array(arrayBuffer);

            for (var i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }

            return new Blob([intArray], {
                type: content_type
            });
        }
    },
    setup() {
        const obj = cachePostCode();
        const form = itemHook();
        const isUpdate = ref(false);

        form.stopUnitWatch();

        form.form.value = {
            address: {
                // 发货地址ID
                shipper_address_id: localStorage.getItem('cache_shipper_address_id') ? JSON.parse(localStorage.getItem('cache_shipper_address_id')) : 0,
                country_code: 'US'
            },
            items: []
        }

        // 是否为修改状态
        watch(
            form.obj_all_unit,
            (val) => {
                if (!isUpdate.value)
                    localStorage.setItem('all_unit', JSON.stringify(val));
            }, {
                deep: true
            }
        )
        /* 邮编分析 */
        const analyzePostalCode = AnalyzePostalCode({
            address_form: form.address_form,
            address: form.address,
            rules:form.rules
        });

        return {
            ...obj,
            ...form,
            isUpdate,
            ...analyzePostalCode
        }
    }
}
