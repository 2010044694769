
export default {
    emits: [
        'chooseAddress',
        'update:visible'
    ],
    props: {
        // 弹窗是否打开
        visible: Boolean
    },
    components: {},
    data() {
        return {
            url: '/user/address_book/simple_list',
            columns: [{
                    key: 'index',
                    width: 48,
                    customRender: ({
                        index
                    }) => this.$refs.table.tableIndex + index
                },
                {
                    title: this.$t('address.fields.name'),
                    dataIndex: 'name',
                    width: 200,
                    sorter: true
                },
                {
                    title: this.$t('address.fields.address'),
                    dataIndex: 'address1',
                    sorter: true,
                    slots: {
                        customRender: 'address'
                    }
                },
                {
                    title: this.$t('address.fields.city'),
                    dataIndex: 'city',
                    sorter: true,
                    width: 150
                },
                {
                    title: this.$t('address.fields.state_code'),
                    dataIndex: 'state_code',
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t('address.fields.postal_code'),
                    dataIndex: 'postal_code',
                    width: 100,
                    sorter: true
                },
                {
                    title: this.$t('address.fields.country_code'),
                    dataIndex: 'country_code',
                    width: 100,
                    sorter: true
                },
                {
                    title: this.$t('share.operation'),
                    key: 'action',
                    width: 60,
                    align: 'center',
                    slots: {
                        customRender: 'action'
                    }
                }
            ],
            // 表格搜索条件
            where: {}
        };
    },
    methods: {
        /* 更新visible */
        updateVisible(value) {
            this.$emit('update:visible', value);
        },
        /* 搜索 */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /*  重置搜索 */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        choose(row) {
            this.$emit('chooseAddress', row);
            this.updateVisible(false);
        }
    }
}
